export const PATH_HOME = "/";
export const PATH_ABOUT = "/About";
export const PATH_SERVICE = "/Service";
export const PATH_CONTACT = "/Contact";
export const PATH_APPOINTMENT = "/Appointment";

export const PORT_COQUITLAM = "Port Coquitlam";
export const NORTH_VANCOUVER = "North Vancouver";
export const ADMIN_NORTH_VAN = "infonorth@bcautoglass.ca";
export const ADMIN_PORT_COQUI = "info@bcautoglass.ca";
export const ADMIN_PORT_COQUI_2 = "rezamosavat@hotmail.com";